import React from 'react';
import styled from 'styled-components';

import * as typeformEmbed from '@typeform/embed'

const Container = styled.section`
  background-color: #ffffff;
  width: 50%;
`;


const SubmitButton = styled.button`
  background-color: #001dfb;
  color: #ffffff;
  padding: 1.2rem;
  cursor: pointer;
  width: 20rem;
  font-size: 1.4rem;
  text-transform: uppercase;
  border: 0;
  box-shadow: none;
  margin: 4rem auto;
  display: block;
`;


class Form extends React.Component {
    componentDidMount() {
        const popup = typeformEmbed.makePopup("https://byma.typeform.com/to/yhgatj", {
            mode: 'popup',
        })
        document.getElementById('popupOpenBtn').addEventListener('click', () => popup.open())
    }
    render() {
        return (
          <Container>
            <h2
              css={`
                font-weight: 600;
                letter-spacing: 1.2;
                text-align: center;
              `}
            >
                REGISTRE-SE AGORA   
            </h2>
                <SubmitButton id="popupOpenBtn">Clique para Aplicar</SubmitButton>
          </Container>
        )
    }
};

export default Form;
